.contact_page_contacts {
  color: #404042;
  list-style: none;
  /* margin-left: 0; */
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact_page_contacts a {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  color: #404042;
}

.contact_page_contacts a:hover {
  color: var(--bg-primary);
}

.app__contact_page_icon {
  width: 40px;
}

@media (min-width: 380px) {
  .contact_page_contacts {
    font-size: 22px;
  }
}
