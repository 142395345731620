.homepage__intro_btn {
  width: 100%;
  /* text-align: right; */
}
.homepage__intro_btn a {
  font-size: 22px;
  border: none;
  border-radius: 25px;
  background-color: #404042;
  color: #fff;
  padding: 6px 20px;
  cursor: pointer;
  /* max-width: 150px; */
  max-width: 100%;
  width: 100%;
  text-decoration: none;
  display: block;
  text-align: center;
}

.homepage__intro_btn a:hover {
  background-color: #fff;
  color: #404042;
}

.homepage__intro_img {
  max-width: 450px;
  width: 100%;
  border-radius: 25px;
  /* height: 100%; */
  margin-top: auto;
  margin-bottom: auto;
}

.homepage_product_carousel_btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.homepage_product_carousel_btn a {
  padding: 12px 24px;
  background-color: #f05821;
  color: #fff;
  border: none;
  border-radius: 20px;
  font-size: 22px;
  cursor: pointer;
}

.homepage_product_carousel_btn a:hover {
  background-color: #404042;
}

@media (min-width: 576px) {
  .homepage__intro_btn a {
    font-size: 22px;
    border: none;
    border-radius: 25px;
    background-color: #404042;
    color: #fff;
    padding: 6px 20px;
    cursor: pointer;
    max-width: 250px;
    width: 100%;
    text-decoration: none;
    display: inline !important;
  }
}
