.product_page_large_img {
  max-width: 450px;
  width: 100%;
  height: auto;
  border-radius: 25px;
  cursor: pointer;
}

.product_image_list {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}
.product_page_small_img {
  width: 125px;
  height: 125px;
  object-fit: cover;
  border-radius: 25px;
  cursor: pointer;
}
