.product_page li {
  margin-left: 15px;
}

.product_page_button button {
  font-size: 42px;
  background-color: var(--bg-primary);
  border: 0;
  border-radius: 25px;
  color: #fff;
  padding: 6px 20px;
  cursor: pointer;
}

.product_page_button {
  margin-top: 25px;
}

.product_price {
  margin-top: 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.product_price p {
  text-align: left !important;
  font-size: 32px;
}

.product_price .other_text {
  font-size: 20px;
}

@media (max-width: 768px) {
  .product_page_button {
    margin-top: 25px;
    width: 100%;
  }

  .product_page_button button {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .product_page_button {
    margin-top: 75px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  .product_page_button button {
    font-size: 42px;
    background-color: var(--bg-primary);
    border: 0;
    border-radius: 25px;
    color: #fff;
    padding: 6px 20px;
    cursor: pointer;
  }
}
