:root {
  --text-primary: #404042;
  --text-secondary: #f05821;
  --text-awarness: #ffffff;
  --bg-primary: #f05821;
  --bg-secondary: #f58a64;
  /* --bg-primary: #065fb7; */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Madimi One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

html,
body {
  scroll-behavior: smooth;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(180, 35, 35, 0);
  background-color: #fff;
}
p {
  color: #404042;
  font-size: 22px;
}

::selection {
  background-color: var(--bg-primary);
  color: #ffffff;
}

.secondary__bg {
  background-color: var(--bg-secondary) !important;
}

.round__corners {
  border-radius: 25px;
}
.shadow {
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.fade-in {
  animation: fadeIn 1s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.app__full_width {
  max-width: 100% !important;
  width: 100%;
}

.d-flex_inline {
  display: flex;
  align-items: center;
}

.d-flex-center {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.d-flex-center-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.d-flex-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.app__container {
  max-width: 100%;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 15px;
  padding-right: 15px;

  background-image: url("../media/clouds_edited.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.wrapper {
  max-width: 1200px;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  background-color: #fff;
  border-radius: 25px;
  /* border: 5px solid var(--bg-primary); */
  border: 5px solid #f058214a;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.row {
  max-width: 1080px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  gap: 15px;
  padding: 10px;

  /* background-color: #fff; */
}

.m-bottom {
  margin-bottom: 15px;
}
.col-1 {
  width: 100%;
}

.col-2 {
  width: 100%;
}

.col-3 {
  max-width: 100%;
  width: 100%;
}

@media (min-width: 576px) {
  .col-1 {
    width: 32%;
  }

  .col-2 {
    width: 48%;
  }

  .col-3 {
    max-width: 100%;
    width: 100%;
  }
  .d-flex-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .d-flex-center-col {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .d-flex-end {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

@media (min-width: 768px) {
  .col-1 {
    width: 32%;
  }

  .col-2 {
    width: 48%;
  }

  .col-3 {
    max-width: 100%;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .col-1 {
    width: 32%;
  }

  .col-2 {
    width: 48%;
  }

  .col-3 {
    max-width: 100%;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .col-1 {
    width: 32%;
  }

  .col-2 {
    width: 48%;
  }

  .col-3 {
    max-width: 100%;
    width: 100%;
  }
}

@media (min-width: 1400px) {
  .col-1 {
    width: 32%;
  }

  .col-2 {
    width: 48%;
  }

  .col-3 {
    max-width: 100%;
    width: 100%;
  }
}

h1 {
  font-size: 42px;
  color: var(--bg-primary);
}

h2 {
  font-size: 36px;
  color: #404042;
}

/* form */
form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

::placeholder {
  color: #40404260;
  opacity: 1; /* Firefox */
  font-size: 20px;
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #40404260;
  font-size: 20px;
}

input {
  max-width: 360px;
  height: 35px;
  width: 100%;
  border-radius: 50px;
  background-color: #f9f9f9;
  border: #474ab612 solid 1px;
  padding-left: 15px;
  color: var(--bg-primary);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
  font-size: 20px;
}

input:focus {
  border: #f05821 1px solid;
}

textarea {
  max-width: 360px;
  height: 150px;
  width: 100%;
  border-radius: 25px;
  background-color: #f9f9f9;
  border: #474ab612 solid 1px;
  resize: none;
  padding: 15px;
  color: var(--bg-primary);
  font-size: 20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

textarea:focus {
  border: #f05821 1px solid;
}
.app__form_button {
  background-color: var(--bg-primary);
  max-width: 360px;
  width: 100%;
  border-radius: 50px;
  border: none;
  padding: 6px;
  color: #fff;
  font-size: 20px;
}

ul li::marker {
  color: var(--bg-primary);
  font-size: 1.5em;
}
