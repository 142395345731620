.product_image_modal {
  background-color: #333;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.product_image_modal img {
  max-width: 1200px;
  width: 100%;
}

.product_image_modal .close_btn {
  position: fixed;
  right: 15px;
  top: 100px;
  font-size: 50px;
  z-index: 111;
  color: rgb(136, 136, 136);
  cursor: pointer;
}

.image_left_icon_holder {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #33333340;
  padding: 0 15px;
  font-size: 50px;
  color: #fff;
  cursor: pointer;
}

.image_right_icon_holder {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #33333340;
  padding: 0 15px;
  font-size: 50px;
  color: #fff;
  cursor: pointer;
}

@media (min-width: 576px) {
  .product_image_modal .close_btn {
    position: fixed;
    right: 15px;
    top: 50px;
    font-size: 50px;
    z-index: 111;
    color: rgb(136, 136, 136);
    cursor: pointer;
  }
}
