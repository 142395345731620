.app__privacy_page_bg {
  display: flex;
  flex-direction: row;
  padding-top: 125px;
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.app__privacy_page_block {
  width: 80%;
  margin-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
}

.app__privacy_page_update_block {
  width: 100%;
  text-align: right;
}
.app__privacy_page_container a {
  text-decoration: none;
  color: #404042;
  font-weight: 700;
}

.app__privacy_page_container strong {
  color: #404042;
}

.app__privacy_page_container ul {
  padding-left: 25px;
  list-style: none;
  color: #404042;
}

.app__privacy_page_container ul li::before {
  content: "\2022";
  color: #f05821;
  font-weight: bold;
  display: inline-block;
  width: 1em;
}

.app__privacy_page_container h2,
h3,
p,
strong,
ul {
  margin-bottom: 5px;
}

.app__privacy_page_container p {
  color: #454545;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}

.app__privacy_page_container h2 {
  color: #f05821;
  font-size: 22px;
  font-weight: 700;
}

.app__privacy_page_container h3 {
  color: #333;
  font-size: 18px;
  font-weight: 700;
}
