.app__footer {
  background-color: var(--bg-primary);
  padding-bottom: 10px;
}

.app__footer_bg {
  background-image: url("../../assets/media/footer_bg.png");
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-position: center;
  background-size: cover;
}

.app__footer_logo {
  max-width: 320px;
  width: 100%;
}

.app__footer_list_items {
  color: #fff;
  list-style: none;
  font-size: 20px;
}

.app__footer_list_items,
.app__footer_list_items a {
  color: #fff;
  list-style: none;
  font-size: 20px;
}

.app__footer_list_items a:hover {
  color: #333;
}

.app__footer h3 {
  font-size: 22px;
  color: #fff;
}

.contact_item a {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  gap: 5px;
}

.contact_item img {
  max-width: 25px;
  width: 25px;
}

.footer__social_icon_wrapper {
  width: 100%;
  display: flex;
}

.footer__social_icon {
  max-width: 50px;
  cursor: pointer;
}

.footer_signature {
  font-size: 14px;
  color: #f9b79f;
}
