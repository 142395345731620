.about_us_page_image {
  max-width: 1080px;
  width: 100%;
  margin-top: 25px;
  border-radius: 25px;
}

.about_us_intro p {
  margin-bottom: 25px !important;
  /* background-color: aqua; */
}
