.gallery__page_img {
  max-width: 250px;
  width: 100%;
  height: 200px;
  object-fit: cover;
  border: 2px solid var(--bg-primary);
  border-radius: 25px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

/* .gallery__page_img:hover {
  transform: scale(1.1);
  transition: ease-in all 0.2s;
} */

.gallery__wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
}

@media (min-width: 380px) {
  .gallery__page_img {
    max-width: 250px;
    width: 100%;
    height: 200px;
    object-fit: cover;
    border: 2px solid var(--bg-primary);
    border-radius: 25px;
    margin-left: 0;
    margin-right: 0;
    cursor: pointer;
  }
}
