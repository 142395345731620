/* .app_navitems_mobile {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
} */
.menu-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.app__hamburger_menu_div.opened {
  /* position: absolute;
  top: 100px;
  right: 0; */
  position: absolute;
  width: 100%;
  padding: 15px;
  background-color: var(--bg-primary);
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  z-index: 9999 !important;
  animation: scaleDownFromTop 0.3s forwards;
}

.app__hamburger_menu_div.closed {
  display: none;
  animation: scaleUpFromBottom 0.3s forwards;
}

.app_navitems_mobile.closed {
  /* animation: scaleUpFromBottom 0.3s forwards; */
  display: none;
}

.app_navitems_mobile .opened {
  /* position: relative; */
  top: 100%;
  right: 0;
  width: 100%;
  padding: 15px;
  background-color: #515151 !important;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 25px;
  z-index: 999;

  animation: scaleDownFromTop 0.3s forwards;
}

.dropdown-item {
  margin: 0 1rem;
  text-decoration: none;
  color: #fff;
  text-align: center;
  font-size: 32px;
  list-style: none;
}

@keyframes scaleDownFromTop {
  0% {
    transform: scaleY(0);
    transform-origin: top;
  }
  100% {
    transform: scaleY(1);
    transform-origin: top;
  }
}

@keyframes scaleUpFromBottom {
  0% {
    transform: scaleY(1);
    transform-origin: top;
  }
  100% {
    transform: scaleY(0);
    transform-origin: top;
  }
}

.app__header_flag_mobile {
  width: 50px;
}

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  height: 80px;
  z-index: 9999 !important;
}
