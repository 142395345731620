.app__product_wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
}

.app__product_wrapper a {
  text-decoration: none;
}

.app__product_card {
  border: 2px solid var(--bg-primary);
  border-radius: 25px;
  max-width: 250px;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  text-decoration: none;
}

a {
  text-decoration: none !important;
}
.app__product_card_img {
  overflow: hidden;
}
.app__product_card_img img {
  width: 250px;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.app__product_card_img img:hover {
  transform: scale(1.1);
  transition: ease-in all 0.2s;
}

.app__product_card_title {
  padding-left: 15px;
  height: 80px;
  margin-top: 5px;
}
.app__product_card_title h2 {
  font-size: 24px;
  line-height: 24px;
  color: var(--bg-primary);
}

.app__product_card_price {
  padding-left: 15px;
}
.app__product_card_price p {
  font-size: 22px;
}

.app__product_card_button {
  padding-left: 10px;
  padding-bottom: 20px;
  margin-top: 10px;
}
.app__product_card_button a {
  font-size: 22px;
  border: none;
  border-radius: 25px;
  background-color: var(--bg-primary);
  color: #fff;
  padding: 6px 20px;
  cursor: pointer;
  max-width: 150px;
  width: 100%;
  text-decoration: none;
}

.app__product_card_button a:hover {
  background-color: #404042;
}

@media (min-width: 350px) {
  .app__product_card {
    border: 2px solid var(--bg-primary);
    border-radius: 25px;
    width: 300px;
    cursor: pointer;
    overflow: hidden;
    text-decoration: none;
  }
}

@media (min-width: 576px) {
  .app__product_card {
    border: 2px solid var(--bg-primary);
    border-radius: 25px;
    width: 250px;
    cursor: pointer;
    overflow: hidden;
    text-decoration: none;
  }
}

@media (min-width: 890px) {
  .app__product_wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 25px;
    justify-content: center;
  }
}
