.reservation_modal {
  /* background-color: #fff; */
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 999;
}

.reservation_form_container {
  background-color: #fff;
  border: 5px solid var(--bg-primary);
  border-radius: 25px;
  max-width: 600px;
  width: 100%;
  padding: 15px;
}

.reservation_form_container .reservation_close_form_button {
  color: rgb(136, 136, 136);
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
  font-size: 25px;
}

.reservation_modal label {
  max-width: 360px;
  width: 100%;
  color: #404042;
  margin-left: 10px;
}
