.app__header_title {
  color: #fff;
  font-size: 18px;
}

.app__header_text {
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-size: 16px;
}

.app__header_text:hover {
  color: #333;
}

.header__icon {
  max-width: 25px;
}
.primary_bg {
  background-color: var(--bg-primary);
}

.app__header_hero {
  /* height: 600px; */
  min-height: 250px;
  background-image: url("../../assets/media/hero_img.jpg");
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-position: top;
  background-size: cover;
  margin-top: 80px;
}

@media (min-width: 576px) {
  .app__header_hero {
    /* height: 600px; */
    min-height: 400px;
    background-image: url("../../assets/media/hero_img.jpg");
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-position: top;
    background-size: cover;
    margin-top: 0px;
  }
  .app__header_title {
    color: #fff;
    font-size: 22px;
  }
}

@media (min-width: 768px) {
  .app__header_hero {
    min-height: 400px;
  }

  .app__header_text {
    font-size: 14px;
  }
  .header__icon {
    max-width: 25px;
  }
}

@media (min-width: 992px) {
  .app__header_hero {
    min-height: 500px;
  }

  .app__header_text {
    font-size: 16px;
  }
  .header__icon {
    max-width: 25px;
  }
}

@media (min-width: 1200px) {
  .app__header_hero {
    min-height: 500px;
  }

  .app__header_text {
    font-size: 18px;
  }
}

@media (min-width: 1400px) {
  .app__header_hero {
    min-height: 60vh;
    background-image: url("../../assets/media/hero_img_extra_large.png");
  }
}

.app_navitems_desktop {
  display: flex;
  flex-direction: row;
  list-style: none;
  /* gap: 15px; */
  margin-left: auto;
  margin-right: auto;
  font-size: 22px;
  color: var(--bg-primary);
  cursor: pointer;
}

.app__navitem {
  border-left: solid 1px var(--bg-primary);
  padding-left: 20px;
  padding-right: 20px;
}

.app__navitem:last-of-type {
  border-right: solid 1px var(--bg-primary);
}

.app__header_flag {
  height: 25px;
  cursor: pointer;
}

.app_navitems_desktop a {
  text-decoration: none;
  color: var(--bg-primary);
}

.app_navitems_desktop a:hover {
  background-color: var(--bg-primary);
  color: #fff;
}
