.separated_products_wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 25px; */
  /* gap: 25px; */
}

.comined_product_symbols {
  color: #404042;
  font-size: 72px;
}

.separated_product_card {
  transform: scale(0.8);
}

@media (min-width: 690px) {
  .separated_products_wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: 25px;
    gap: 25px;
  }

  .comined_product_symbols {
    color: #404042;
    font-size: 72px;
  }

  .separated_product_card {
    transform: scale(1);
  }
}
